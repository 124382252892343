<template>
  <section class="l-content">
    <el-card name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="关键字">
          <el-input v-model="condition.keyword" placeholder="部门名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getDataList()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider></el-divider>
    <el-card v-if="dataList">
      <l-table :option="tableOption" :data="dataList"> </l-table>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import departAddoredit from './depart_addoredit'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'Depart',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '部门名称', prop: 'departName' },
          { label: '描述', prop: 'departDesc' }
        ],
        operation: {
          width: '260',
          header: { type: 'warning', icon: 'el-icon-plus', label: '新增', click: this.add },
          buttons: [
            { type: 'primary', icon: 'el-icon-edit', label: '编辑', click: this.edit },
            { type: 'danger', icon: 'el-icon-delete', label: '删除', click: this.del }
          ]
        }
      },
      condition: {
        keyword: ''
      },
      dataList: null
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.dataList = await this.$lgh.get('api/enterprise/depart/list', ['enterpriseCode', this.condition])
    },
    async add() {
      await this.$lgh.openDialog({
        ins: departAddoredit,
        title: '新增部门信息',
        data: {
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode,
            createUserName: this.userInfo.fullName
          }
        }
      })
      this.$lgh.toastSuccess('新建部门信息成功')
      this.getDataList()
    },
    async edit(item) {
      await this.$lgh.openDialog({
        ins: departAddoredit,
        title: '修改部门信息',
        data: {
          dataInfo: Object.assign({}, item)
        }
      })
      this.$lgh.toastSuccess('修改部门信息成功')
      this.getDataList()
    },
    del(item) {
      this.$lgh.msgConfirm('确认删除部门信息?', async () => {
        await this.$lgh.get('api/enterprise/depart/delete', {
          enterpriseCode: item.enterpriseCode,
          departId: item.departId
        })
        this.$lgh.notifySuccess('删除成功', item.departName + '信息已删除')
        this.getDataList()
      })
    }
  }
}
</script>
